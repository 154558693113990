import React from "react";
import { Text } from "rebass/styled-components";
import Link, { LinkProps } from "./Link";

type Props = LinkProps & {
  name: string;
};

const NavLink = (props: Props) => {
  const { name } = props;
  return (
    <Link {...props}>
      <Text as="span" variant="variants.nav">
        {name}
      </Text>
    </Link>
  );
};

export default NavLink;
