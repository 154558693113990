import { Box } from "rebass/styled-components";

interface Props {
  children: React.ReactNode;
  [key: string]: any;
}

const NavItem = ({ children, ...rest }: Props) => {
  return (
    <Box
      as="li"
      pl={4}
      sx={{
        display: "inline",
        fontWeight: "bold"
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default NavItem;
