import gql from "graphql-tag";
import { CurrentUserFragment } from "../fragments/index.fragments";

const validateLogin = apolloClient =>
  apolloClient
    .query({
      query: gql`
        query getCurrentUser {
          currentUser {
            ...CurrentUserParts
          }
        }
        ${CurrentUserFragment}
      `
    })
    .then(({ data }) => {
      return data.currentUser;
    })
    .catch(() => {
      return null;
    });

export default validateLogin;
