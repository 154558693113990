import { useState, useContext, useEffect, ReactElement } from "react";
import { withRouter, NextRouter } from "next/router";
import { Flex, Box, Text } from "rebass/styled-components";
import useTranslation from "next-translate/useTranslation";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";

import Icon from "./common/Icon";
import Button from "./common/Button";
import Modal from "./common/Modal";
import NavItem from "./common/NavItem";
import NavLink from "./common/NavLink";
import Logo from "./common/Logo";
import Link from "./common/Link";
import { useAuthDispatch, useAuthState } from "./common/AuthContext";
import AvatarBadge from "./common/AvatarBadge";

import { CurrentUser, Notification } from "../interfaces";
import { LocaleContext } from "./locale";
import { UserType } from "../enums";
import publicRuntimeConfig from "../lib/public-runtime-config";
import { useIsMobile } from "../lib/media";
import flash from "../lib/flash";
import { logout as authLogout } from "../lib/auth";
import { useApolloClientWithResetCallback } from "../lib/apolloClient";
import styles from "../lib/styles/headerStyles";

interface Props {
  currentUser?: CurrentUser;
  transparent?: boolean;
  immersive?: boolean;
  router: NextRouter;
  PreHeader?: ReactElement;
  displayCountrySelector?: boolean;
}

interface NavItemProps {
  currentUser?: CurrentUser;
  transparent?: boolean;
  immersive?: boolean;
  visibileOnMobile: boolean;
  notifications: Notification;
}

interface NavItemPhoneNumberProps {
  transparent?: boolean;
  immersive?: boolean;
  visibileOnMobile: boolean;
}

interface NavItemCountrySelectorProps {
  country?: string | null;
  transparent?: boolean;
  immersive?: boolean;
  visibileOnMobile?: boolean;
  isMobile?: boolean;
  toggleCountrySelector?: () => void;
}

interface NavLinkProps {
  transparent?: boolean;
  immersive?: boolean;
  name: string;
  href: string;
  icon: string;
  visibileOnMobile: boolean;
  notification?: number;
}

interface CountrySelectorModalProps {
  country: string | null;
  open: boolean;
  size: string;
  onClose: () => void;
}

const settingsQuery = gql`
  query getSettings {
    settings {
      showSupportPhoneNumber
      supportPhoneNumber
    }
  }
`;

const hrefLangAlternate = publicRuntimeConfig.hrefLangAlternate;

const CountrySelectorModal = ({
  country,
  open,
  size,
  onClose
}: CountrySelectorModalProps) => {
  const { t } = useTranslation("common");

  return (
    <Modal
      open={open}
      size={size}
      onClose={onClose}
      onOutsideClick={onClose}
      HeaderButton={<Box></Box>}
    >
      <Box p={[0]} py={[3]}>
        <Text variant="h3" mb={2} textAlign="left">
          {t("selectCountry")}
        </Text>

        <Box sx={styles.countrySel.container}>
          {hrefLangAlternate.map((hrefLang, index) => {
            return (
              hrefLang.country && (
                <a
                  key={index}
                  href={hrefLang.href}
                  hrefLang={hrefLang.locale}
                  style={{
                    textDecoration: "none"
                  }}
                >
                  <Flex sx={styles.countrySel.iconContainer}>
                    <Icon fontSize={6} name={`flags/${hrefLang.country}`} />
                    <Text mt={1} sx={styles.countrySel.iconText}>
                      {t(`country-${hrefLang.locale}`)}
                    </Text>
                  </Flex>
                </a>
              )
            );
          })}
        </Box>
      </Box>
    </Modal>
  );
};

const NavItemCountrySelector = ({
  country,
  visibileOnMobile,
  transparent,
  immersive,
  isMobile,
  toggleCountrySelector
}: NavItemCountrySelectorProps) => {
  const { t } = useTranslation("common");

  return (
    <>
      <NavItem>
        <Flex
          sx={{ cursor: "pointer", alignItems: "center" }}
          onClick={toggleCountrySelector}
        >
          {!isMobile && (
            <Text mr={1} sx={{ fontWeight: "regular" }}>
              {t(`country-${country}`)}
            </Text>
          )}

          <Icon fontSize={4} name={`flags/${country}`} />

          {!isMobile && (
            <Box ml={1} pt={1}>
              <Icon
                name="caret-down"
                color={
                  !visibileOnMobile && transparent && !immersive
                    ? "samoyedWhite"
                    : "labradorBlack"
                }
              />
            </Box>
          )}
        </Flex>
      </NavItem>
    </>
  );
};

const NavItemHelp = () => {
  const { t } = useTranslation("header");
  const { country } = useContext(LocaleContext);

  return (
    <NavItem>
      <Text as="a" variant="variants.nav" href={process.env.help[country]}>
        {t("help")}
      </Text>
    </NavItem>
  );
};

const NavItemSearch = () => {
  const { t } = useTranslation("header");

  return (
    <Flex justifyContent="flex-end" mb={["4px", 0]}>
      <Flex sx={styles.itemSearch.container}>
        <Flex sx={styles.itemSearch.iconContainer}>
          <Icon name="search" color="samoyedWhite" />
        </Flex>

        <Box mx={1} sx={{ color: "labradorBlack" }}>
          <NavLink name={t("search")} href="/search" />
        </Box>
      </Flex>
    </Flex>
  );
};

const NavItemPhoneNumber = (props: NavItemPhoneNumberProps) => {
  const { data: settingsData } = useQuery(settingsQuery);
  const { t } = useTranslation("header");
  const { transparent, immersive, visibileOnMobile } = props;
  // TODO: change this
  return t("header:phoneNumber") &&
    settingsData?.settings?.showSupportPhoneNumber ? (
    <NavItem display={["none", "none", "none", "block"]}>
      <Flex
        as="a"
        variant="variants.nav"
        href={`tel:${t("phoneNumber")}`}
        sx={{ alignItems: "center", justifyContent: "flex-end" }}
      >
        <Icon
          name="phone"
          color={
            !visibileOnMobile && transparent && !immersive
              ? "samoyedWhite"
              : "labradorBlack"
          }
        />
        <Box as="span" fontSize={4} fontWeight="book" ml={1}>
          {t("phoneNumber")}
        </Box>
      </Flex>
    </NavItem>
  ) : null;
};

const NavItemDefault = (props: NavLinkProps) => {
  const {
    transparent,
    immersive,
    name,
    href,
    icon,
    visibileOnMobile,
    notification
  } = props;

  return (
    <NavItem>
      <Flex alignItems="center" justifyContent="flex-end">
        <Icon
          name={icon}
          color={
            !visibileOnMobile && transparent && !immersive
              ? "samoyedWhite"
              : "labradorBlack"
          }
        />
        <Box ml={1}>
          <NavLink name={name} href={href} />
        </Box>
        {!!notification && (
          <Box sx={styles.itemDefault.notification}>{notification}</Box>
        )}
      </Flex>
    </NavItem>
  );
};

const NavItems = (props: NavItemProps) => {
  const { currentUser, notifications } = props;

  const { t } = useTranslation("header");
  const { accountMode } = useAuthState();
  const isMobile = useIsMobile();

  const openIntercom = () => {
    if (window.Intercom) {
      window.Intercom("show");
    }
  };

  if (!currentUser) {
    return (
      <>
        <NavItemSearch />

        <NavItem
          sx={{
            display: ["block", "none", "none", "none", "block"]
          }}
        >
          <NavLink name={t("howItWorks")} href="/howitworks" />
        </NavItem>

        <NavItem
          sx={{
            display: ["block", "none", "none", "none", "block"]
          }}
        >
          <NavLink name={t("services")} href="/ourservices" />
        </NavItem>

        <NavItem
          sx={{
            display: ["block", "none", "none", "none", "block"]
          }}
        >
          <NavLink name={t("becomeASitter")} href="/recruitingsitters" />
        </NavItem>

        <NavItem>
          <NavLink name={t("signup")} href="/signup" />
        </NavItem>

        <NavItem>
          <NavLink name={t("login")} href="/login" />
        </NavItem>

        {isMobile && (
          <>
            <NavItemHelp />

            <NavItem>
              <Text onClick={openIntercom}>{t("chat")}</Text>
            </NavItem>
          </>
        )}
      </>
    );
  } else {
    if (accountMode == UserType.CAREGIVER) {
      return (
        <>
          <NavItemDefault
            {...props}
            name={t("messages")}
            href="/conversations"
            icon="messages"
            notification={notifications.unreadMessagesCountCaregiver}
          />
          <NavItemDefault
            {...props}
            name={t("bookings")}
            href="/caregiver/bookings"
            icon="bell"
          />
          <NavItemDefault
            {...props}
            name={t("availability")}
            href="/caregiver/calendar"
            icon="calendar"
          />
          <NavItemHelp />
        </>
      );
    } else {
      return (
        <>
          <NavItemSearch />
          <NavItemDefault
            {...props}
            name={t("messages")}
            href="/conversations"
            icon="messages"
            notification={notifications.unreadMessagesCountUser}
          />
          <NavItemDefault
            {...props}
            name={t("yourPack")}
            href="/user/dogs"
            icon="dog"
          />

          <NavItem>
            <NavLink name={t("howItWorks")} href="/howitworks" />
          </NavItem>

          <NavItem>
            <NavLink name={t("services")} href="ourservices" />
          </NavItem>
        </>
      );
    }
  }
};

const Header = (props: Props) => {
  const { currentUser, transparent, immersive, displayCountrySelector } = props;

  const { t } = useTranslation("header");
  const client = useApolloClientWithResetCallback();
  const isMobile = useIsMobile();
  const { locale, country } = useContext(LocaleContext);
  const authDispatch = useAuthDispatch();
  const { accountMode, loggedInAsVisilibity } = useAuthState();
  const [visibileOnMobile, setVisibleOnMobile] = useState(false);
  const [flashHeader, setFlashHeader] = useState({
    showPasswordResetHeader: false,
    forgotPasswordEmail: "",
    passwordResetSuccessful: false
  });
  const [shouldShowCountrySelector, setShouldShowCountrySelector] = useState(
    displayCountrySelector
  );

  //Styles
  const containerPosition = [!immersive ? "absolute" : "initial", "absolute"];
  const containerHeader = {
    borderBottom: !immersive ? 0 : ["default", 0]
  };

  const {
    showPasswordResetHeader,
    forgotPasswordEmail,
    passwordResetSuccessful
  } = flashHeader;

  const showBadge =
    currentUser &&
    isMobile &&
    (accountMode == UserType.CAREGIVER
      ? currentUser.notifications.unreadMessagesCountCaregiver > 0
      : currentUser.notifications.unreadMessagesCountUser > 0);

  const toggleCountrySelector = () => {
    setShouldShowCountrySelector(!shouldShowCountrySelector);
  };

  const openUserMenu = () => {
    authDispatch({ type: "showUserMenu" });
  };

  const logout = () => {
    authLogout({
      client,
      locale
    });

    authDispatch({ type: "hideLoggedInAs" });
  };

  useEffect(() => {
    const value = flash.get("forgotPasswordEmail");

    if (value) {
      setFlashHeader({
        showPasswordResetHeader: true,
        forgotPasswordEmail: value,
        passwordResetSuccessful: false
      });
    }
    if (flash.get("passwordResetSuccessful")) {
      setFlashHeader({
        showPasswordResetHeader: true,
        forgotPasswordEmail: "",
        passwordResetSuccessful: true
      });
    }
  }, []);

  return (
    <>
      <Box
        width={1}
        className={
          showPasswordResetHeader ? "" : transparent ? "transparent" : ""
        }
        sx={{
          ...styles.container,
          "&.transparent": {
            ...styles.containerTransparent,
            position: containerPosition,
            header: containerHeader
          }
        }}
      >
        {!!props.PreHeader && props.PreHeader}

        {loggedInAsVisilibity && currentUser && (
          <Flex as="header" sx={styles.flexVisibility}>
            <Text>{t("loggedInAs", { name: currentUser.name })}</Text>
            <Text ml={1} sx={{ cursor: "pointer" }} onClick={logout}>
              {t("clickToLogout")}
            </Text>
          </Flex>
        )}

        {!showPasswordResetHeader && (
          <Flex as="header" sx={styles.infoContainer}>
            <Box sx={{ cursor: "pointer" }}>
              <Link href="/">
                <Logo
                  color={
                    transparent && !immersive ? "samoyedWhite" : "inuOrange"
                  }
                  height={["24px", "32px"]}
                  width={["80px", "100px"]}
                  name={"logo"}
                ></Logo>
              </Link>
            </Box>

            <Box>
              <Flex
                as="ul"
                className={visibileOnMobile ? "mobile" : ""}
                sx={{
                  ...styles.itemsContainer.general,
                  display: [
                    "none !important",
                    "none !important",
                    !immersive ? "flex !important" : "none"
                  ],
                  "&.mobile": {
                    ...styles.itemsContainer.mobile,
                    display: visibileOnMobile ? "block !important" : "none"
                  }
                }}
              >
                {visibileOnMobile && (
                  <li>
                    <Icon
                      as="button"
                      name="close"
                      fontSize={0}
                      onClick={() => setVisibleOnMobile(false)}
                    />
                  </li>
                )}

                <NavItems
                  {...props}
                  visibileOnMobile={visibileOnMobile}
                  notifications={currentUser && currentUser.notifications}
                />

                {currentUser && (
                  <AvatarBadge
                    ml={4}
                    mr={1}
                    onClick={() => openUserMenu()}
                    src={currentUser.avatar.circle}
                    alt={`${currentUser.name} avatar`}
                    width={32}
                    height={32}
                    showBadge={showBadge}
                    data-test="avatar"
                  />
                )}

                {!isMobile && (
                  <NavItemCountrySelector
                    {...props}
                    country={country}
                    isMobile={isMobile}
                    toggleCountrySelector={toggleCountrySelector}
                  />
                )}
              </Flex>
            </Box>

            <Box
              sx={{
                display: visibileOnMobile ? "none" : ["block", "block", "none"]
              }}
            >
              <Flex ml="auto" alignItems="center">
                <NavItemCountrySelector
                  {...props}
                  country={country}
                  isMobile={isMobile}
                  toggleCountrySelector={toggleCountrySelector}
                />

                <Text color="samoyedWhite" px={2}>
                  |
                </Text>

                <Box as="button" sx={styles.mobileAvatar}>
                  {currentUser ? (
                    <AvatarBadge
                      ml={0}
                      mr={1}
                      onClick={() => openUserMenu()}
                      src={currentUser.avatar.circle}
                      alt={`${currentUser.name} avatar`}
                      width={32}
                      height={32}
                      showBadge={showBadge}
                    />
                  ) : (
                    <Icon
                      fontSize={2}
                      name="burger"
                      onClick={() => setVisibleOnMobile(true)}
                      color={
                        transparent && !immersive
                          ? "samoyedWhite"
                          : "labradorBlack"
                      }
                    />
                  )}
                </Box>
              </Flex>
            </Box>
          </Flex>
        )}

        {showPasswordResetHeader && (
          <Flex as="header" sx={styles.passwordReset.container}>
            <Box sx={styles.passwordReset.infoContainer}>
              {passwordResetSuccessful && currentUser && (
                <Text>
                  {t("passwordResetSuccessful", {
                    name: currentUser.name
                  })}
                </Text>
              )}
              {!!forgotPasswordEmail && (
                <Text>
                  {t("passwordResetEmailSent", {
                    email: forgotPasswordEmail
                  })}
                </Text>
              )}
            </Box>
            <Box>
              <Button
                variant="link"
                onClick={() => {
                  setFlashHeader({
                    ...flashHeader,
                    showPasswordResetHeader: false
                  });
                }}
              >
                <Icon name="close" />
              </Button>
            </Box>
          </Flex>
        )}

        <CountrySelectorModal
          country={country}
          open={shouldShowCountrySelector || false}
          size={isMobile ? "full" : "verySmall"}
          onClose={toggleCountrySelector}
        />
      </Box>
    </>
  );
};

export default withRouter(Header);
