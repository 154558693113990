import React, { useContext } from "react";
import load from "little-loader";

import { LocaleContext } from "../locale";

type GooglePlaces = typeof google.maps.places;
type AutocompleteService = google.maps.places.AutocompleteService;

interface GooglePlaceValues {
  googlePlaces: GooglePlaces;
  autocompleteService: AutocompleteService;
}

interface GooglePlacesContextType {
  isInProvider: boolean;
  googlePlaces?: GooglePlaces;
  autocompleteService?: AutocompleteService;
  apiKey?: string;
  update?: (values: GooglePlaceValues) => void;
}

export const GooglePlacesContext = React.createContext<GooglePlacesContextType>(
  {
    isInProvider: false,
    googlePlaces: undefined,
    autocompleteService: undefined,
    apiKey: undefined,
    update: () => {}
  }
);

interface ProviderProps {
  apiKey: string;
  children: React.ReactNode;
}

export const GooglePlacesProvider = ({ apiKey, children }: ProviderProps) => {
  const existingValues = useContext(GooglePlacesContext);

  const [values, setValues] = React.useState<GooglePlacesContextType>({
    googlePlaces: existingValues.googlePlaces,
    autocompleteService: existingValues.autocompleteService,
    isInProvider: existingValues.isInProvider,
    apiKey
  });

  const update = (newValues: GooglePlaceValues) => {
    setValues(values => ({
      ...values,
      isInProvider: true,
      googlePlaces: newValues.googlePlaces,
      autocompleteService: newValues.autocompleteService
    }));
  };

  return (
    <GooglePlacesContext.Provider value={{ ...values, update }}>
      {children}
    </GooglePlacesContext.Provider>
  );
};

export const useLoadGooglePlaces = () => {
  const values = useContext(GooglePlacesContext);
  const { locale } = useContext(LocaleContext);

  React.useEffect(() => {
    if (!values.isInProvider) {
      load(
        `https://maps.googleapis.com/maps/api/js?key=${values.apiKey}&libraries=places&language=${locale}`,
        () => {
          values?.update &&
            values.update({
              googlePlaces: google.maps.places,
              autocompleteService: new google.maps.places.AutocompleteService()
            });
        }
      );
    }
  }, [locale, values.apiKey, values.isInProvider]);
};
