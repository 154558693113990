const routes = require("./lib/routes.json");

const pages = {
  "*": ["common", "header", "footer", "error"]
};

Object.entries(routes).forEach(function([name, route]) {
  pages[name] = route.localeNamespaces;
});

module.exports = {
  locales: ["es", "fr", "de", "en", "en-IE", "da", "sv", "no"],
  defaultLocale: "en",
  pages: pages
};
