import { useEffect } from "react";
import cookie from "cookie";

export function useUTMTracking() {
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let utmSource = params.get("utm_source");
    let utmMedium = params.get("utm_medium");
    let utmCampaign = params.get("utm_campaign");

    if (utmSource || utmMedium || utmCampaign) {
      const cookieAge = 30 * 24 * 60 * 60; // 1 month

      document.cookie = cookie.serialize("utmSource", utmSource || "", {
        maxAge: cookieAge
      });
      document.cookie = cookie.serialize("utmMedium", utmMedium || "", {
        maxAge: cookieAge
      });
      document.cookie = cookie.serialize("utmCampaign", utmCampaign || "", {
        maxAge: cookieAge
      });
    }
  }, []);
}

export function getUTMTracking() {
  const utmSource = cookie.parse(document.cookie)["utmSource"] || undefined;
  const utmMedium = cookie.parse(document.cookie)["utmMedium"] || undefined;
  const utmCampaign = cookie.parse(document.cookie)["utmCampaign"] || undefined;

  return {
    utmSource,
    utmMedium,
    utmCampaign
  };
}
