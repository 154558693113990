import { useMediaQuery } from "react-responsive";
import { breakpoints } from "./theme";
import { useState, useEffect, useRef } from "react";
import { GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import { ParsedUrlQuery } from "querystring";
import UAParser from "ua-parser-js";

export function useIsMobile(deviceType?: string) {
  const device =
    deviceType == "mobile" ? { width: breakpoints.medium - 1 } : undefined;
  return useMediaQuery({ maxWidth: breakpoints.medium - 1 }, device);
}

// Desktop or tablet
export function useIsDesktop(deviceType?: string) {
  const device =
    deviceType != "mobile" ? { width: breakpoints.medium } : undefined;
  return useMediaQuery({ minWidth: breakpoints.medium }, device);
}

// Hook
export function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    const size = {
      width: isClient ? `${window.innerWidth}px` : undefined,
      height: isClient ? `${window.innerHeight}px` : undefined
    };

    return size;
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

export function useIntersect({ root = null, rootMargin, threshold = 0 }) {
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);

  const observer = useRef(
    new IntersectionObserver(([entry]) => updateEntry(entry), {
      root,
      rootMargin,
      threshold
    })
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) currentObserver.observe(node);

    return () => currentObserver.disconnect();
  }, [node]);

  return [setNode, entry];
}

// Here we extend the Next.js GetServerSideProps function type
// to include a new prop `DeviceType` in the `context`
type GetServerSidePropsWithDeviceType<
  P extends { [key: string]: any } = { [key: string]: any },
  Q extends ParsedUrlQuery = ParsedUrlQuery
> = (
  context: GetServerSidePropsContext<Q> & { deviceType: string }
) => Promise<GetServerSidePropsResult<P>>;

export const withDeviceType = () => (
  getServerSidePropsFn?: GetServerSidePropsWithDeviceType
) => async (ctx: GetServerSidePropsContext) => {
  const { req } = ctx;

  const parser = new UAParser(req.headers["user-agent"]);

  const { type } = parser.getDevice();

  let returnData = { props: { ...(type && { deviceType: type }) } };

  // Evaluate the composed getServerSideProps().
  if (getServerSidePropsFn) {
    // Add the DeviceType to Next.js context so pages can use
    // it in `getServerSideProps`, if needed.
    const getServerSidePropsResult = await getServerSidePropsFn({
      ...ctx,
      deviceType: type
    });

    const { props = {}, ...rest } = getServerSidePropsResult;

    returnData = {
      props: { ...returnData.props, ...props },
      ...rest
    };
  }
  return returnData;
};
