import { createContext, ReactNode } from "react";

export const LocaleContext = createContext({
  locale: null,
  country: null
});

interface ProviderProps {
  children: ReactNode;
}

export default LocaleContext.Provider;
