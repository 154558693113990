import { Box } from "rebass/styled-components";
import styled from "styled-components";
import { breakpoints } from "../../lib/theme";

const Card: typeof Box = styled(Box)`
  background-color: ${({ theme }) => theme.colors.samoyedWhite};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray};
  border-radius: ${({ theme }) => theme.radii.default}px;
  box-shadow: ${({ theme }) => theme.shadows.inputFocus};
  @media (max-width: ${breakpoints.small - 1}px) {
    &.mobile-borderless {
      border: 0;
      border-radius: 0px;
      box-shadow: none;
    }
  }
`;

export default Card;
