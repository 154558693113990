import getConfig from "next/config";
import Bugsnag, { BreadcrumbType, Breadcrumb, Event } from "@bugsnag/js";
import { CurrentUser } from "../generated/graphql";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";

const { publicRuntimeConfig } = getConfig();

export const setUser = (user?: CurrentUser) => {
  if (user) {
    Bugsnag.setUser(user.id, user?.email || "", user.name || "");
  }
};

export const leaveBreadcrumb = (
  breadcrumb?: string,
  metadata?: { [key: string]: any },
  type?: BreadcrumbType
) => {
  if (breadcrumb) {
    Bugsnag.leaveBreadcrumb(breadcrumb, metadata, type);
  }
};

//filtering automated breadcrumbs
const onBreadcrumb = (breadcrumb: Breadcrumb) => {
  switch (breadcrumb.type) {
    case "user":
      if (breadcrumb.metadata.targetText == "(...)") return false;
      break;
    case "request":
      if (!breadcrumb.metadata.status || breadcrumb.metadata.status == 200)
        return false;
      break;
  }
};

export const onError = (event: Event) => {
  if (
    event.errors[0]?.errorMessage?.match(/ResizeObserver loop limit exceeded/)
  ) {
    return false;
  }

  // Checking whether error happens server side or client side
  event.addMetadata("execution", { server: typeof window === "undefined" });
};

Bugsnag.start({
  apiKey: publicRuntimeConfig.bugsnagKey,
  plugins: [new BugsnagPluginReact(React)],
  enabledReleaseStages: ["production", "staging"],
  enabledBreadcrumbTypes: ["error", "user", "request"],
  releaseStage:
    process.env.NODE_ENV == "development" ? "development" : "production",

  onBreadcrumb: onBreadcrumb,
  onError: onError
});
