const styles = {
  container: {
    zIndex: ["auto", 5],
    position: ["relative", "sticky"],
    top: ["auto", 0],
    background: "white"
  },
  containerTransparent: {
    zIndex: 5,
    background: "transparent",
    top: 0,
    "button svg": {
      color: "samoyedWhite"
    },
    "ul:not(.mobile)": {
      li: {
        color: "samoyedWhite"
      }
    }
  },
  flexVisibility: {
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    bg: "successGreen",
    color: "samoyedWhite",
    px: [1, 3],
    py: 1
  },
  infoContainer: {
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "default",
    px: [1, 3],
    py: 2
  },
  itemsContainer: {
    general: {
      alignItems: "center",
      ml: "auto",
      "list-style": "none",
      "text-align": "right"
    },
    mobile: {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      width: "100%",
      m: 0,
      py: 1,
      px: 2,
      bg: "background",
      zIndex: 9999,
      li: {
        display: "block",
        py: 1
      }
    }
  },
  mobileAvatar: {
    marginTop: "3px",
    fontSize: 0,
    "text-align": "right",
    cursor: "pointer",
    p: 0,
    background: "none",
    border: "none",
    marginRight: 1
  },
  passwordReset: {
    container: {
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      bg: "pugYellow",
      px: [1, 3],
      py: 2
    },
    infoContainer: {
      textAlign: "center",
      flex: 1
    }
  },
  countrySel: {
    container: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gap: "0px 0px"
    },
    iconContainer: {
      flexDirection: "column",
      alignItems: "center",
      justifyConten: "center",
      py: 2,
      px: 2,
      borderRadius: 4,
      ":hover": {
        boxShadow: "0 1px 8px 0 #ddd"
      }
    },
    iconText: {
      textAlign: "center",
      fontWeight: "regular",
      color: "labradorBlack"
    }
  },
  itemSearch: {
    container: {
      width: "fit-content",
      alignItems: "center",
      justifyContent: "flex-end",
      backgroundColor: "white",
      py: 1,
      pl: 1,
      pr: 1,
      borderRadius: 100,
      border: "1px solid",
      borderColor: "lightGray"
    },
    iconContainer: {
      backgroundColor: "inuOrange",
      justifyContent: "center",
      alignItems: "center",
      width: 28,
      height: 28,
      borderRadius: 16,
      paddingBottom: "2px"
    }
  },
  itemDefault: {
    notification: {
      width: "max-content",
      ml: 1,
      px: 1,
      color: "samoyedWhite",
      backgroundColor: "inuOrange",
      borderRadius: "circle"
    }
  }
};

export default styles;
