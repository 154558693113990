export default {
  set: function(key, value) {
    localStorage.setItem(key, value);
  },
  get: function(key) {
    if (typeof window != "undefined") {
      const value = localStorage.getItem(key);
      localStorage.removeItem(key);
      return value;
    } else {
      return null;
    }
  }
};
