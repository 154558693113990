import { useMemo } from "react";
import cookie from "cookie";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split
} from "@apollo/client";

import { useApolloClient } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import publicRuntimeConfig from "./public-runtime-config";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";
import { setContext } from "apollo-link-context";
import { calcS } from "./utils";
import ActionCableLink from "./action-cable-link";
import { GetServerSidePropsContext } from "next";
import { useAuthDispatch } from "../components/common/AuthContext";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient: ApolloClient<NormalizedCacheObject>;

function createApolloClient(
  locale: string,
  serverSideAuthToken?: string | null
) {
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  const isBrowser = typeof window !== "undefined";

  const httpLink = new HttpLink({
    uri: ({ operationName }) => {
      return `${publicRuntimeConfig.gudogApi}?op=WEB_${operationName}`;
    },
    fetch
    // credentials: "same-origin",
    // resolvers: {}
  });

  const localeLink = setContext((request, { headers }) => {
    return {
      headers: {
        ...headers,
        "Gudog-Locale": locale
        // "Gudog-Timezone": "UTC"
      }
    };
  });

  //setting cypress header so tests don't fail due to reCaptcha
  const cypressLink = setContext((request, { headers }) => {
    return {
      headers: {
        ...headers,
        ...(window?.Cypress ? { "Gudog-Cypress-Test": 1 } : {})
      }
    };
  });

  const signatureLink = setContext((request, { headers }) => {
    return { headers: { ...headers, signature: calcS() } };
  });

  const authLink = setContext((request, { headers }) => {
    let token = serverSideAuthToken;
    if (isBrowser) {
      token =
        cookie.parse(document.cookie)["a_token"] ||
        cookie.parse(document.cookie)["token"];
    }

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    };
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent

  const link = !isBrowser
    ? localeLink
        .concat(authLink)
        .concat(signatureLink)
        .concat(httpLink)
    : split(
        // split based on operation type
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        ActionCableLink(),
        localeLink
          .concat(cypressLink)
          .concat(authLink)
          .concat(signatureLink)
          .concat(httpLink)
      );

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link,
    cache: new InMemoryCache({
      possibleTypes: {
        CurrentUser: ["CurrentCaregiver", "CurrentOwner"],
        PaginationInterface: [
          "PaginatedBookings",
          "PaginatedConversation",
          "PaginatedMessages",
          "PaginatedReviews"
        ],
        Reviewable: ["Caregiver", "Dog"],
        User: ["Caregiver", "CurrentCaregiver", "CurrentOwner", "Owner"]
      },
      typePolicies: {
        Country: {
          fields: {
            countryServiceTypes: {
              merge: false
            }
          }
        },
        Booking: {
          fields: {
            dogReviews: {
              merge: false
            },
            availableEvents: {
              merge: false
            }
          }
        },
        Dog: {
          fields: {
            avatar: {
              merge: true
            }
          }
        },
        Caregiver: {
          fields: {
            avatar: {
              merge: true
            }
          }
        }
      }
    })
  });
}

export function initializeApollo(
  locale: string,
  request?: GetServerSidePropsContext["req"] | null,
  initialState = null
): ApolloClient<NormalizedCacheObject> {
  // Fetch impersonation token (a_token) or the normal token
  const serverSideAuthToken = request
    ? request.cookies.a_token || request.cookies.token
    : null;

  const _apolloClient =
    apolloClient ?? createApolloClient(locale, serverSideAuthToken);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s)))
      ]
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: any
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(locale: string, pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(locale, null, state), [
    locale,
    state
  ]);
  return store;
}

/**
 * This custom hook return the apollo client with a callback for store clearing already set.
 * In this callback we're resetting the accountMode in the AuthContext. The reason for doing it
 * is so queries that use the account mode as a condition for being skipped do not run after the
 * apollo store is cleared on logout.
 */
export function useApolloClientWithResetCallback() {
  const client = useApolloClient();
  const authDispatch = useAuthDispatch();

  const resetAccountMode = () =>
    new Promise<void>(resolve => {
      authDispatch({
        type: "setAccountMode",
        payload: { accountMode: undefined }
      });
      resolve();
    });

  client.onClearStore(resetAccountMode);

  return client;
}
