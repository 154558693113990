import { createContext, ReactNode } from "react";

export function getMetaPropsForLocale(locale: string) {
  switch (locale) {
    case "en":
      return { domain: "https://gudog.co.uk", ogLocale: "en_GB" };
    case "en-IE":
      return { domain: "https://gudog.ie", ogLocale: "en_IE" };
    case "es":
      return { domain: "https://gudog.com", ogLocale: "es_ES" };
    case "fr":
      return { domain: "https://gudog.fr", ogLocale: "fr_FR" };
    case "de":
      return { domain: "https://gudog.de", ogLocale: "de_DE" };
    case "da":
      return { domain: "https://gudog.dk", ogLocale: "da_DK" };
    case "no":
      return { domain: "https://gudog.no", ogLocale: "no_NO" };
    case "sv":
      return { domain: "https://gudog.se", ogLocale: "sv_SE" };
    default:
      return { domain: "https://gudog.co.uk", ogLocale: "en_GB" };
  }
}

export const MetaContext = createContext({
  ogLocale: null,
  domain: null,
  url: null
});

interface ProviderProps {
  children: ReactNode;
}

export default MetaContext.Provider;
