import { Flex, Text } from "rebass/styled-components";
import Image from "next/image";

function Trustpilot() {
  return (
    <Flex alignItems="center" sx={{ gap: "12px" }}>
      <Image
        alt="Trustpilot Rating"
        src={`/images/trustpilot-rating.png`}
        width={100}
        height={18}
      />
      <Image
        alt="Trustpilot Logo"
        src={`/images/trustpilot-logo.png`}
        width={100}
        height={25}
        style={{ marginBottom: "4px" }}
      />
    </Flex>
  );
}

export default Trustpilot;
