import { fontFace, lighten } from "polished";

const colors = {
  samoyedWhite: "#ffffff",
  pugYellow: "#F9B342",
  inuOrange: "#ED553B",
  mongrelBrown: "#5B2B2F",
  labradorBlack: "#010101",
  springGreen: "#3CB4A0",
  aquamarine: "#1F8C8D",
  prusianBlue: "#033243",
  lightGray: "#EBEBEB",
  gray: "#DDDDDD",
  darkGray: "#9A9A9A",
  errorRed: "#B31412",
  veryDarkGray: "#181818",
  successGreen: "#6DD400"
};

//Alias
colors.primary = colors.inuOrange;
colors.black = colors.labradorBlack;
colors.background = colors.samoyedWhite;

// Default breakpoints borrwed from Bootstrap
// https://getbootstrap.com/docs/4.3/layout/overview/
export const breakpoints = {
  small: 576, // mobile devices
  medium: 768, // ipad, ipad pro, ipad mini, etc
  large: 992, // smaller laptops
  xlarge: 1200 // laptops and desktops
};

const theme = {
  breakpoints: Object.values(breakpoints).map(val => `${val}px`),
  colors: { ...colors },
  fonts: {
    body: "Geomanist",
    heading: "inherit",
    monospace: "Menlo, monospace"
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 64, 96, 40],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
    // Font-face mapping for weights
    book: 300,
    regular: 400,
    medium: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 96],
  sizes: {
    avatar: 48,
    thumb: 56,
    carousel: 64,
    avatarSmallMobile: 24,
    avararSmallDesktop: 32
  },
  radii: {
    default: 4,
    double: 8,
    triple: 16,
    circle: 99999,
    paginationPrev: "50% 0 0 50%",
    paginationNext: "0 50% 50% 0"
  },
  shadows: {
    card: "0 0 4px rgba(0, 0, 0, .125)",
    inputFocus: "0 0 25px 0 rgba(38,55,70,0.1)"
  },
  borders: {
    default: "1px solid #DDDDDD",
    double: "2px solid #DDDDDD"
  },
  letterSpacing: {
    body: "0.5px"
  },
  // rebass variants
  text: {
    h1: {
      fontWeight: "medium",
      fontSize: "40px",
      lineHeight: 48 / 40
    },
    h2: {
      fontWeight: "medium",
      fontSize: "30px",
      lineHeight: 42 / 32
    },
    h3: {
      fontWeight: "book",
      fontSize: "24px",
      lineHeight: 32 / 24
    },
    h4_highlight: {
      fontWeight: "medium",
      fontSize: "16px",
      lineHeight: 24 / 16
    },
    h4: {
      fontWeight: "regular",
      fontSize: "16px",
      lineHeight: 24 / 16
    },
    h5: {
      fontWeight: "regular",
      fontSize: "14px",
      lineHeight: 16 / 14
    },
    note: {
      fontWeight: "regular",
      fontSize: "8px",
      lineHeight: 8 / 8
    },
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading"
    },
    display: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7]
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em"
    }
  },
  variants: {
    avatar: {
      width: "avatar",
      height: "avatar",
      borderRadius: "circle"
    },
    avatarCarousel: {
      width: "carousel",
      height: "carousel",
      borderRadius: "circle"
    },
    avatarSitterProfile: {
      width: ["80px", "152px"],
      height: ["80px", "152px"],
      borderRadius: "circle"
    },
    avatarDogProfile: {
      width: "152px",
      height: "152px",
      borderRadius: "circle"
    },
    avatarLarge: {
      width: "64px",
      height: "64px"
    },
    card: {
      p: 2,
      bg: "background",
      boxShadow: "card"
    },
    link: {
      color: "primary",
      textDecoration: "none"
    },
    secondaryLink: {
      color: "springGreen",
      fontWeight: "book",
      textDecoration: "none"
    },
    nav: {
      fontSize: 2,
      fontWeight: "bold",
      display: "inline-block",
      color: "inherit",
      textDecoration: "none",
      ":hover,:focus,.active": {
        color: "primary"
      }
    },
    error: {
      color: "errorRed",
      borderColor: "errorRed",
      ":hover,:focus": { color: "errorRed", borderColor: "errorRed" },
      "*:not(button)": {
        color: "errorRed",
        borderColor: "errorRed",
        ":hover,:focus": { color: "errorRed", borderColor: "errorRed" }
      }
    },
    layout: {
      large: {
        px: [2, 2, 4, "8.33333vw"]
      },
      semilarge: {
        px: [2, 2, 6, "15vw"]
      },
      medium: {
        px: [2, 2, 8, "25vw"]
      },
      small: {
        px: [2, 2, "25vw", "33.33333vw"]
      },
      sitterProfile: {
        px: [2, 2, 4, "16.66666vw"]
      },
      dashboard: {
        py: [2, 2, 4],
        px: [2, 2, 4, "8.33333vw"],
        mx: "auto"
      },
      dashboardFull: {
        py: [2, 2, 4],
        px: [2, 2, 3, 3],
        mx: "auto"
      },
      caregiverCalendar: {
        pb: [2, 2, 3, 3],
        mx: "auto"
      }
    },
    box: {
      mx: "8.33333vw",
      background: "white",
      border: ["none", "1px solid"],
      borderColor: ["inherit", "gray"],
      borderRadius: "4px",
      boxShadow: ["0", "inputFocus", "inputFocus"]
    },
    borderedBox: {
      background: "samoyedWhite",
      border: "1px solid",
      borderColor: "gray",
      borderRadius: "double"
    },
    topBanner: {
      flexDirection: "row",
      py: 3,
      px: 8,
      backgroundColor: "pugYellow",
      color: "samoyedWhite",
      fontSize: "20px",
      lineHeight: "25px",
      fontWeight: "body"
    },
    topBannerMobile: {
      flexDirection: "column",
      py: 2,
      px: 2,
      mr: 0,
      fontSize: "14px",
      lineHeight: "18px",
      width: "100%"
    },
    topBannerImgBox: {
      minWidth: "142px",
      margin: "auto",
      flexGrow: 1
    },
    topBannerImgBoxMobile: {
      minWidth: 0,
      width: "110px",
      mb: "8px"
    },
    ratingStar: {
      svg: {
        fontSize: 2,
        mr: "2px !important",
        ml: "2px !important",
        ":first-of-type": {
          mr: "2px !important",
          ml: "0px !important"
        },
        ":last-of-type": {
          mr: "0px !important",
          ml: "2px !important"
        }
      }
    },
    serviceIcon: {
      home: {
        bg: "transparent",
        border: "2px solid",
        borderColor: "springGreen",
        borderRadius: "40px",
        width: "96px",
        height: "96px",
        textAlign: "center"
      }
    },
    hero: {
      default: {
        position: "relative",
        height: [240, 240, 648]
      },
      home: {
        position: "relative",
        height: ["inherit", 700, 700, 670, 550],
        minHeight: ["inherit", 700, 700, 670, 550]
      },
      landing: {
        position: "relative"
      },
      reviews: {
        position: "relative",
        height: ["inherit", "inherit", 565],
        px: "16px",
        py: "24px"
      },
      flexChildren: {
        width: "100%",
        zIndex: 2,
        position: "relative",
        alignItems: "center",
        justifyContent: ["flex-start", "center", "center"]
      },
      banner: {
        position: "relative",
        height: ["inherit", "inherit", 770, 770, 720],
        minHeight: ["inherit", "inherit", 770, 770, 720]
      }
    },
    addNew: {
      display: "block",
      cursor: "pointer",
      borderRadius: "50%",
      width: "48px",
      height: "48px",
      fontSize: "1em",
      lineHeight: "48px",
      textAlign: "center",
      color: "gray",
      border: "1px solid",
      borderColor: "springGreen",
      m: 0
    },
    badge: {
      display: "inline-block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "darkGray",
      fontSize: 1,
      border: "default",
      borderRadius: "default",
      height: "24px",
      px: 1,
      svg: {
        my: "auto !important",
        mr: "8px !important"
      }
    },
    caregiverReviewCard: {
      px: 2,
      py: 2,
      border: "default",
      borderRadius: "default"
    },
    caregiverReviewCardDesktop: {
      variant: "variants.caregiverReviewCard",
      "::before": {
        border: "8px solid",
        borderColor: "transparent #DDDDDD #DDDDDD transparent",
        content: `" "`,
        position: "absolute",
        top: 4,
        left: -2
      },
      "::after": {
        border: "7px solid",
        borderColor: "transparent  #ffffff  #ffffff transparent",
        content: `" "`,
        position: "absolute",
        top: "33px",
        left: "-14px"
      }
    },
    caregiverReviewCardMobile: {
      variant: "variants.caregiverReviewCard",
      "::before": {
        border: "8px solid",
        borderColor: "#DDDDDD transparent transparent  #DDDDDD",
        content: `" "`,
        position: "absolute",
        right: 1,
        bottom: -2
      },
      "::after": {
        border: "7px solid",
        borderColor: "#ffffff  transparent  transparent #ffffff",
        content: `" "`,
        position: "absolute",
        right: "9px",
        bottom: "-14px"
      }
    },
    dogOberservationCard: {
      bg: "samoyedWhite",
      border: "1px solid",
      borderColor: "lightGray",
      borderRadius: "4px 4px 4px 4px",
      flexGrow: 1,
      position: "relative",
      px: "2",
      py: "1",
      "&.withTail": {
        "::before": {
          border: "8px solid",
          borderColor: "#EBEBEB transparent transparent #EBEBEB",
          content: `" "`,
          position: "absolute",
          left: "24px",
          bottom: "-16px"
        },
        "::after": {
          border: "7px solid",
          borderColor: "#ffffff transparent transparent #ffffff",
          content: `" "`,
          position: "absolute",
          left: "25px",
          bottom: "-14px"
        }
      }
    },
    round: {
      borderRadius: "50%",
      width: "24px",
      height: "24px",
      justifyContent: "center",
      alignItems: "center"
    },
    dogHair: {
      background: "url('/images/patterns/dog-hair.svg')",
      backgroundRepeat: "repeat",
      backgroundSize: [0, 0, "200px"]
    },
    messageForm: {
      bg: "lightGray",
      border: "1px solid",
      borderColor: "lightGray",
      borderRadius: "24px 24px 24px 24px",
      color: "darkGray",
      alignItems: "center",
      flex: "1",
      mr: "4px"
    },
    sendMessageButton: {
      backgroundColor: "springGreen",
      borderRadius: "50%",
      width: "56px",
      height: "56px",
      alignItems: "center",
      justifyContent: "center"
    },
    plusButton: {
      display: "block",
      cursor: "pointer",
      borderRadius: "circle",
      width: "48px",
      height: "48px",
      fontSize: 1,
      lineHeight: "48px",
      textAlign: "center",
      color: "gray",
      border: "default",
      borderColor: "springGreen",
      m: 0
    }
  },
  buttons: {
    large: {
      fontSize: "18px",
      lineHeight: "24px",
      px: 2,
      py: 0,
      minHeight: "48px"
    },
    medium: {
      fontSize: "18px",
      lineHeight: "24px",
      px: 2,
      py: 0,
      minHeight: "40px"
    },
    small: {
      fontSize: "18px",
      lineHeight: "24px",
      borderRadius: "dobule",
      minHeight: "24px",
      px: 2,
      py: 0
    },
    primary: {
      cursor: "pointer",
      fontWeight: "bold",
      color: "background",
      bg: "primary",
      borderRadius: "default",
      ":hover": {
        bg: lighten(0.05, colors.primary)
      },
      ":focus": {
        outline: "none"
      },
      ":disabled": {
        bg: lighten(0.2, colors.primary)
      }
    },
    secondary: {
      variant: "buttons.primary", //  Inherits from prev variant
      bg: "springGreen",
      ":hover": {
        bg: lighten(0.2, colors.springGreen) // TODO add transparency
      },
      ":disabled": {
        bg: lighten(0.2, colors.springGreen)
      }
    },
    terciary: {
      variant: "buttons.primary",
      color: "black",
      bg: "transparent",
      border: "default",
      ":hover": {
        borderColor: "black"
      },
      ":disabled": {
        color: lighten(0.2, "black"),
        borderColor: "default"
      }
    },
    link: {
      variant: "buttons.terciary",
      border: "0px solid",
      color: "inuOrange",
      fontWeight: "book",
      ":hover": {
        bg: "transparent"
      }
    }
  },
  modal: {
    verySmall: {
      width: "450px",
      maxHeight: "95vh",
      maxWidth: "95vw"
    },
    small: {
      width: "500px",
      maxHeight: "95vh",
      maxWidth: "95vw"
    },
    medium: {
      width: "800px",
      maxHeight: "95vh",
      maxWidth: "95vw"
    },
    large: {
      width: "1140px",
      maxHeight: "95vh",
      maxWidth: "95vw"
    },
    veryLarge: {
      width: "1350px",
      maxHeight: "95vh",
      maxWidth: "95vw"
    },
    full: {
      height: "100%",
      width: "100%"
    },
    largeFullHeight: {
      width: "1140px",
      height: "100%",
      maxHeight: "86vh",
      maxWidth: "95vw"
    }
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body"
    }
  },
  forms: {
    checkbox: {
      mr: 1,
      color: "springGreen",
      "input:checked + &": {
        display: "block"
      },
      "input:checked ~ &": {
        color: "springGreen"
      },
      "input:focus ~ &": {
        color: "springGreen"
      }
    },
    radio: {
      mr: 1,
      color: "springGreen",
      "input:checked + &": {
        display: "block"
      },
      "input:checked ~ &": {
        color: "springGreen"
      },
      "input:focus ~ &": {
        color: "springGreen"
      }
    }
  },
  userDogs: {
    mainFlex: {
      flexDirection: ["column", "column", "row"],
      flexWrap: "wrap"
    },
    mainBox: {
      width: ["100%", "100%", "33.33%"]
    },
    linkFlex: {
      my: 1,
      ml: 1,
      fontSize: 3,
      alignItems: "center"
    },
    linkFlexBox: {
      ml: 4
    },
    linkText: {
      color: "springGreen",
      ml: 1,
      cursor: "pointer"
    },
    dogTile: {
      mainBox: {
        px: 2,
        py: 3,
        cursor: "pointer",
        color: "samoyedWhite"
      },
      flex: {
        flexWrap: "wrap"
      }
    },
    newDog: {
      mainBox: {
        width: "100%",
        p: 1
      },
      wrapBox: {
        pt: "198px",
        border: "1px dashed",
        borderColor: "springGreen",
        backgroundColor: "samoyedWhite",
        borderRadius: "double",
        overflow: "hidden",
        position: "relative"
      },
      detailBox: {
        position: "absolute",
        top: "72px",
        right: 0,
        left: 0,
        cursor: "pointer"
      },
      flex: {
        flexDirection: "column",
        alignItems: "center"
      },
      text: {
        mt: 1,
        color: "springGreen",
        fontSize: 3
      }
    }
  },
  bookingTile: {
    mainFlex: {
      color: "samoyedWhite",
      fontWeight: "bold",
      padding: 2,
      alignItems: "flex-start"
    },
    boxService: {
      flex: "0 0 auto"
    },
    boxIcon: {
      position: "relative",
      backgroundColor: "samoyedWhite",
      padding: [4, 4, 3, 4],
      svg: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        fontSize: 6
      }
    },
    boxInfo: {
      flexGrow: 1,
      pl: 1,
      fontWeight: "regular",
      ".ellipsis": {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
    },
    boxPrice: {
      flex: "0 0 auto",
      alignSelf: "flex-end",
      flexGrow: 1
    },
    flexPrice: {
      width: 1,
      fontSize: 4,
      fontWeight: "book",
      flexDirection: "row-reverse",
      mr: [6, 6, 0]
    },
    labelBox: {
      py: "2px",
      px: 1,
      backgroundColor: "samoyedWhite",
      borderRadius: "circle",
      marginTop: 1,
      marginRight: 1,
      fontSize: 1
    }
  }
};

export default theme;
