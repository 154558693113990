import { LinkProps } from "next/link";
import Router from "next/router";
import { compile } from "path-to-regexp";
import { ParsedUrlQueryInput } from "querystring";

import routes from "./routes.json";

export type Href =
  | string
  | {
      pathname: string;
      query?: Record<string, any>; // Params needed for building the URL
      search?: Record<string, any>; // search query params
      otherQueryParams?: Record<string, any>; // Any other query params
    };

export function resolveHref(href: Href, locale: string) {
  let resolvedHref: LinkProps["href"];

  // 1. href="/search"
  // 2. href={ pathname: "/search/city", query: {city: "london"}}
  // 3. href={ pathname: "/search/city", query: {city: "london"}, search: { extraParam: "value1" }}
  try {
    // 1. Find the URL for the desired route (href) and locale
    if (typeof href === "string") {
      resolvedHref = routes[`${href}`]?.locales[locale];
    } else {
      const toPath = compile(routes[`${href.pathname}`]?.locales[locale], {
        encode: encodeURIComponent
      });

      // 2. and 3.
      resolvedHref = { pathname: toPath(href.query as ParsedUrlQueryInput) };

      // 3.
      if (href.search || href.otherQueryParams) {
        // We let Next.js convert the search/otherQueryParams object into a string, by passing it through href.query
        resolvedHref.query = {
          ...(href.search || {}),
          ...(href.otherQueryParams || {})
        };
      }
    }

    return resolvedHref;
  } catch {
    console.debug(`Link Error - route not found for ${locale}`);
    console.debug({ href });
    return null;
  }
}

export function pushRoute(href: Href, options = {}) {
  Router.push(resolveHref(href, Router.locale), undefined, options);
}

export function replaceRoute(href: Href, options = {}) {
  Router.replace(resolveHref(href, Router.locale), undefined, options);
}
