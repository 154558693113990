import React from "react";
import { Box, Flex } from "rebass/styled-components";
import { useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import OutsideClickHandler from "./OutsideClickHandler";
import Button from "./Button";
import Icon from "./Icon";
import Heading from "./Heading";

const modalRoot = typeof window === "undefined" ? null : document.body;

export const ModalWrapper = props => {
  const { onDidMount, onWillUnmount, children } = props;

  React.useEffect(() => {
    onDidMount && onDidMount();
    return () => onWillUnmount && onWillUnmount();
  }, [onDidMount, onWillUnmount]);

  // Create a div that we'll render the modal into. Because each
  // Modal component has its own element, we can render multiple
  // modal components into the modal container.

  const elem = typeof document != "undefined" && document.createElement("div");
  const rootElemRef = useRef(elem);

  useEffect(function setupElement() {
    const currentRootElementRef = rootElemRef.current;

    modalRoot.style.overflowY = "hidden";
    // Add the detached element to the parent
    modalRoot.appendChild(currentRootElementRef);

    // This function is run on unmount
    return function removeElement() {
      modalRoot.style.overflowY = "";
      currentRootElementRef.remove();
    };
  }, []);

  if (!modalRoot) {
    return null;
  }

  return createPortal(children, rootElemRef.current);
};

interface Props {
  children: React.ReactNode;
  open: boolean;
  onOutsideClick: () => void;
  onClose: () => void;
  FooterButton?: JSX.Element;
  HeaderButton?: JSX.Element;
  size: any;
  HeaderTitle?: any;
  HeaderDirection?: string;
  FooterHeight?: string;
  onDidMount?: () => void;
  onWillUnmount?: () => void;
  showHeader?: boolean;
}

interface PropsFooter {
  children: React.ReactNode;
  height: string;
}

interface PropsHeader {
  onClose: () => void;
  HeaderButton?: JSX.Element;
  HeaderTitle?: any;
  flexDirection?: string;
}

const ModalHeader = ({
  onClose,
  HeaderButton,
  HeaderTitle,
  flexDirection
}: PropsHeader) => {
  return (
    <Flex
      flexDirection={flexDirection ? flexDirection : "row"}
      mt={2}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        borderBottom: "default",
        backgroundColor: "samoyedWhite"
      }}
    >
      {onClose && (
        <Button variant="link" onClick={() => onClose()} sx={{ zIndex: 1 }}>
          <Icon name="close" />
        </Button>
      )}
      {HeaderTitle && (
        <Heading
          as="h4"
          variant="highlight"
          sx={!HeaderButton && { flex: 1, textAlign: "center", ml: "-40px" }}
        >
          {HeaderTitle}
        </Heading>
      )}
      {HeaderButton}
    </Flex>
  );
};

const ModalFooter = ({ children, height }: PropsFooter) => {
  return (
    <Box
      py={2}
      px={1}
      sx={{
        position: "sticky",
        bottom: 0,
        width: "100%",
        height: height,
        borderTop: "default",
        backgroundColor: "samoyedWhite",
        zIndex: 99999
      }}
    >
      {children}
    </Box>
  );
};

const Modal = ({
  open,
  onOutsideClick,
  children,
  FooterButton,
  HeaderButton,
  onClose,
  size,
  HeaderTitle,
  HeaderDirection,
  FooterHeight,
  onDidMount,
  onWillUnmount,
  showHeader
}: Props) => {
  if (!open) {
    return null;
  }

  return (
    <ModalWrapper onDidMount={onDidMount} onWillUnmount={onWillUnmount}>
      <Box
        sx={{
          backgroundColor: "rgba(0,0,0,0.5)",
          height: "100%",
          width: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 999
        }}
      >
        <OutsideClickHandler onOutsideClick={onOutsideClick}>
          <Box
            sx={{
              position: "absolute",
              overflow: "auto",
              width: "100%",
              bg: "background",
              variant: `modal.${size}`,
              alignItems: "center",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%) !important"
            }}
          >
            <Flex
              sx={{
                position: "relative",
                flexDirection: "column",
                height: "100%"
              }}
            >
              {(HeaderButton || showHeader) && (
                <ModalHeader
                  onClose={onClose}
                  HeaderButton={HeaderButton}
                  HeaderTitle={HeaderTitle}
                  flexDirection={HeaderDirection ? HeaderDirection : "row"}
                />
              )}
              <Box
                sx={{
                  px: 2,
                  paddingBottom: FooterButton ? "80px" : 0
                }}
              >
                {children}
              </Box>

              {FooterButton && (
                <ModalFooter height={FooterHeight || "0px"}>
                  {FooterButton}
                </ModalFooter>
              )}
            </Flex>
          </Box>
        </OutsideClickHandler>
      </Box>
    </ModalWrapper>
  );
};

Modal.defaultProps = {
  open: false,
  onClose: () => {},
  onOutsideClick: () => {},
  size: "small",
  FooterHeight: "80px"
};

export default Modal;
