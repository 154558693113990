/* eslint-disable @typescript-eslint/camelcase */
import React, { createContext, useReducer, useContext, useEffect } from "react";
import cookie from "cookie";
import { useGetCurrentUserQuery } from "../../generated/graphql";

type Action =
  | {
      type: "showUserMenu";
    }
  | {
      type: "hideUserMenu";
    }
  | {
      type: "showLoggedInAs";
    }
  | {
      type: "hideLoggedInAs";
    }
  | {
      type: "setAccountMode";
      payload: { accountMode?: string };
    };

type Dispatch = (action: Action) => void;

export interface State {
  userMenuVisibility: boolean;
  loggedInAsVisilibity: boolean;
  accountMode?: string;
}

interface AuthProviderProps {
  children: React.ReactNode;
  value: State;
}

const AuthStateContext = createContext<State | undefined>(undefined);
const AuthDispatchContext = createContext<Dispatch | undefined>(undefined);

function AuthReducer(state: State, action: Action) {
  switch (action.type) {
    case "showUserMenu":
      return { ...state, userMenuVisibility: true };
    case "hideUserMenu":
      return { ...state, userMenuVisibility: false };
    case "showLoggedInAs":
      return { ...state, loggedInAsVisilibity: true };
    case "hideLoggedInAs":
      return { ...state, loggedInAsVisilibity: false };
    case "setAccountMode": {
      const { accountMode } = action.payload;

      // TODO: reconsider this.
      // Probably it's not a good idea to perform side effects on a Context Reducer
      // However we haven't found a better place to do this without having to duplicate code
      // every time we need to switch the accountMode.
      if (accountMode) {
        const cookieAge = 30 * 24 * 60 * 60;

        document.cookie = cookie.serialize("accountMode", accountMode || "", {
          maxAge: cookieAge,
          path: "/"
        });
      }

      return { ...state, accountMode };
    }

    default: {
      throw new Error();
    }
  }
}

// This hook updates Intercom user on app mount if user is already auth
function useUpdateIntercomUser(currentUser: any) {
  useEffect(() => {
    // Set a 1s timeout to wait for window.Intercom to be available
    setTimeout(() => {
      if (window.Intercom && currentUser) {
        window.Intercom("update", {
          email: currentUser.email,
          user_id: currentUser.id,
          user_hash: currentUser.intercomUserHash
        });
      }
    }, 1000);
  }, [currentUser]);
}

function AuthProvider({ value, children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(AuthReducer, value);

  const { data } = useGetCurrentUserQuery();

  useUpdateIntercomUser(data?.currentUser);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

AuthProvider.defaultProps = {
  initialState: {
    userMenuVisibility: false,
    loggedInAsVisilibity: false
  }
};

function useAuthState() {
  const context = useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }

  return context;
}

function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuthState, useAuthDispatch };
