import {
  format,
  parseISO,
  isAfter,
  differenceInDays,
  endOfDay
} from "date-fns";
import {
  DatesFilterValue,
  DateRange,
  MultipleDates,
  User,
  CurrencyIso,
  MGPBrowserInfo
} from "../interfaces";
import { Caregiver, Booking, CaregiverStatus } from "../generated/graphql";
import {
  DatesFilterMode,
  TimeSelectorMode,
  BookingStatus,
  UserType
} from "../enums";
import { enGB, es, de, fr, da, sv, nb } from "date-fns/locale";
import { decode as atob, encode as btoa } from "base-64";

export function calculateDatesCount(endDate: Date, startDate: Date) {
  // example: if booking duration si 1 day and 16h, dates count should be 2 days
  return differenceInDays(endOfDay(endDate), endOfDay(startDate));
}

export function dateToIso8601(date: Date): string {
  return format(date, "yyyy-MM-dd");
}

export const zonedToUTC = (timestamp: number) => {
  //strips out the time creating a new date object from GMT 00:00:00
  const date = new Date(timestamp);
  const [year, month, day] = date
    .toISOString()
    .substr(0, 10)
    .split("-")
    .map(item => parseInt(item));
  return new Date(year, month - 1, day);
};

export function datesStringToDatesArray(datesString: string): Date[] {
  return datesString
    .split("/")
    .map(dateString => parseISO(dateString))
    .filter(date => {
      return (
        isAfter(date, new Date()) ||
        date.toDateString() == new Date().toDateString()
      );
    });
}

// https://date-fns.org/docs/I18n
export const dateFormatForLocale = (
  date: Date,
  locale: Locale,
  formatStr = "PP"
) => {
  return format(date, formatStr, {
    locale: locale
  });
};

export function parseDogIdsParam(dogIds: string | string[]): string[] {
  return Array.isArray(dogIds) ? dogIds : [dogIds];
}

export const getDateFilterModeForCountryServiceType = countryServiceType => {
  return countryServiceType &&
    countryServiceType.serviceType.pricePer == "night"
    ? DatesFilterMode.DATE_RANGE
    : DatesFilterMode.MULTIPLE_DATES;
};

export const getTimeFilterModeForCountryServiceType = countryServiceType => {
  if (countryServiceType && !countryServiceType.serviceType.alwaysPickUpDropOff)
    return TimeSelectorMode.DROPOFF_PICKUP_TIME;
  else {
    return TimeSelectorMode.START_TIME;
  }
};

export const getDateFilterModeForServiceType = serviceType => {
  return serviceType && serviceType.pricePer == "night"
    ? DatesFilterMode.DATE_RANGE
    : DatesFilterMode.MULTIPLE_DATES;
};

export const getTimeFilterModeForServiceType = serviceType => {
  if (serviceType && !serviceType.alwaysPickUpDropOff)
    return TimeSelectorMode.DROPOFF_PICKUP_TIME;
  else {
    return TimeSelectorMode.START_TIME;
  }
};

export function isDateRange(value: DatesFilterValue): value is DateRange {
  return value && (value as DateRange).startDate !== undefined;
}

export function isMultipleDates(
  value: DatesFilterValue
): value is MultipleDates {
  return value && (value as MultipleDates).dates !== undefined;
}

export const availableForRequests = (partner: Caregiver | User) => {
  return "services" in partner && !!partner.services.length;
};

export function canBookAgain(booking: Booking, currentUser) {
  if (currentUser.id == booking.userId) {
    return (
      availableForRequests(booking.caregiver) &&
      booking.status == BookingStatus.CAREGIVER_PAID
    );
  } else {
    return false;
  }
}

export function getCurrencySymbol(currency: CurrencyIso) {
  const map = {
    GBP: {
      charCode: 163
    },
    EUR: {
      charCode: 8364
    },
    DKK: "kr.",
    NOK: "kr.",
    SEK: "kr."
  };

  if (typeof map[currency] === "string") {
    return map[currency];
  } else if (typeof map[currency] === "object") {
    return String.fromCharCode(
      (map[currency] as { charCode: number }).charCode
    );
  } else {
    return currency;
  }
}

export function shouldPrefixCurrency(locale: string) {
  const prefixCurrency = !!["en", "en-IE"].find(el => el === locale);
  return prefixCurrency;
}

export function formatCurrency(
  currency: CurrencyIso,
  amount: string | number,
  locale: string,
  stripZeros: boolean
) {
  let moneyToS = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol"
  })
    .format(Number(amount))
    .replace(/\s/g, ""); // remove space between value and currency

  if (stripZeros) {
    moneyToS = moneyToS.replace(/\D00(?=\D*$)/, ""); // strip zeros
  }

  return moneyToS;
}

export function getCaregiverIdAndTitle(user: Caregiver | User) {
  let [caregiverId, title] = [null, null];

  if (user.type == UserType.CAREGIVER) {
    [caregiverId, title] =
      (user as Caregiver).caregiverStatus == CaregiverStatus.Active
        ? (user as Caregiver).slug.split(/-(.+)/)
        : [null, null];
  }

  return [caregiverId, title];
}

export const getDateFnsLocale = locale => {
  const locales = {
    en: enGB,
    "en-IE": enGB,
    es: es,
    fr: fr,
    de: de,
    da: da,
    no: nb,
    sv: sv
  };

  return locales[locale];
};

export function calcS() {
  return btoa(`${Date.now()}.swaphtiwsdneirf`);
}

export function getCountryForLocale(locale: string) {
  switch (locale) {
    case "en":
      return "gb";
    case "en-IE":
      return "ie";
    case "es":
      return "es";
    case "fr":
      return "fr";
    case "de":
      return "de";
    case "da":
      return "dk";
    case "sv":
      return "se";
    case "no":
      return "no";
    default:
      return "gb";
  }
}

export function joinStrings(strings: string[]) {
  return strings.join(", ").replace(/, ([^,]*)$/, " & $1");
}

export function joinAndFormatDates(
  startDate: string,
  endDate: string,
  serviceDates?: string[]
) {
  if (serviceDates) {
    return joinStrings(serviceDates.map(d => format(new Date(d), "dd/M/yy")));
  } else {
    return `${format(new Date(startDate), "dd/M/yy")} > ${format(
      new Date(endDate),
      "dd/M/yy"
    )}`;
  }
}

export const getBrowserInfoFor3DS2 = (): MGPBrowserInfo => {
  // https://bugs.chromium.org/p/chromium/issues/detail?id=1095999
  const colorDepth = screen.colorDepth === 30 ? 24 : 30;

  return {
    language: navigator.language,
    colorDepth: colorDepth,
    screenHeight: screen.height,
    screenWidth: screen.width,
    timeZoneOffset: new Date().getTimezoneOffset().toString(),
    userAgent: navigator.userAgent,
    javascriptEnabled: true
  } as MGPBrowserInfo;
};

// Receives a path (eg: /login?type=User) and a key/value pair and returns
// the same path with that param added:
// eg: addQueryParamToPath("/login?type=User", "hello", "world")
// => "/login?type=User&hello=world"
export function addQueryParamToPath(path: string, key: string, value: string) {
  const [cleanPath, searchParamsString] = path.split("?");
  const searchParams = new URLSearchParams(searchParamsString);
  searchParams.append(key, value);
  return cleanPath + "?" + searchParams.toString();
}

// Regex expression borrowed from https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
export function validateEmail(email: string) {
  // eslint-disable-next-line no-control-regex
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i.test(
    email
  );
}

export function capitalize(s: string) {
  if (!s) return "";
  return s[0].toUpperCase() + s.slice(1);
}
