import { Heading as BaseHeading } from "rebass/styled-components";

// This component extends the Heading from bass
// and uses the value passed as `props.as` as te htext variant
// defined in lib/theme.js
interface Props {
  as?: string;
  variant?: string;
  [x: string]: any;
}

const Heading = (props: Props) => {
  const { as, variant, ...rest } = props;
  // Handle the special case for the H4 Highlight
  const processedVariant =
    variant && variant === "highlight" ? `${as}_${variant}` : variant || as;
  return <BaseHeading variant={processedVariant} as={as} {...rest} />;
};

export default Heading;
