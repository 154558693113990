import { useContext, useEffect, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { isAndroid, isIOS } from "react-device-detect";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

import Button from "./common/Button";
import Icon from "./common/Icon";
import Rating from "./common/Rating";
import { trackEvent } from "../lib/event-tracking";
import { LocaleContext } from "./locale";

function DownloadAppHeader() {
  const { t } = useTranslation("common");

  const router = useRouter();
  const { country } = useContext(LocaleContext);
  const location = router.route.slice(1);

  const isDesktop = !isIOS && !isAndroid;
  const device = isIOS ? "ios" : "android";
  const storeUrl = isIOS
    ? `https://apps.apple.com/${country}/app/gudog/id964470204`
    : "https://play.google.com/store/apps/details?id=com.gudog.app";

  const [visible, setVisible] = useState<boolean>(true);

  const close = () => {
    setVisible(false);
    sessionStorage.setItem("appBanner", "false");
  };

  useEffect(() => {
    if (sessionStorage.getItem("appBanner") === "false") {
      setVisible(false);
    }
  }, []);

  if (!visible || isDesktop) return <></>;

  return (
    <Flex
      width="100%"
      py={1}
      px={2}
      sx={{ borderBottom: "1px solid lightGray" }}
      alignItems="center"
    >
      <Box mt={1} onClick={close} sx={{ cursor: "pointer" }}>
        <Icon name="close" color="darkGrey" fontSize={1} />
      </Box>
      <Box ml={2} mr={2}>
        <Flex
          backgroundColor="inuOrange"
          width="50px"
          height="50px"
          sx={{ borderRadius: "8px" }}
          justifyContent="center"
          alignItems="center"
        >
          <Icon name="gudog" color="samoyedWhite" fontSize={6} />
        </Flex>
      </Box>

      <Flex flexDirection="column" flexGrow={1} sx={{ gap: "4px" }}>
        <Text variant="h4_highlight">{t("appTitle")}</Text>
        <Box
          sx={{
            svg: {
              fontSize: "10px"
            },
            marginTop: "-4px"
          }}
        >
          <Rating score={5} sx={{ padding: "0 !important" }}></Rating>
        </Box>
        <Text variant="h5" color="darkGray">
          {t("appReviews")}
        </Text>
      </Flex>

      <Box>
        <Button
          onClick={() => {
            window.location = storeUrl;
            trackEvent("owner_download_app_intended", {
              device,
              location
            });
          }}
        >
          {t("open")}
        </Button>
      </Box>
    </Flex>
  );
}

export default DownloadAppHeader;
