// Reference:
// https://developers.google.com/tag-manager/enhanced-ecommerce
// https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#ecommerce-data

export function gtmProductName(caregiver) {
  return `${caregiver.name}: ${caregiver.cleanHeadline}`;
}

export function gtmProductId(caregiver) {
  return `${caregiver.id}`;
}

export function gtmProductBrand() {
  return window.location.hostname;
}

export function gtmProductCategory(booking) {
  return `${booking.serviceType.name} / ${booking.caregiver.city.name}`;
}

export function gtmProductVariant(booking) {
  return booking.customized ? "Customized" : "Normal";
}

export function gtmProductPrice(booking) {
  return `${booking.service.price}`;
}

export function gtmProductQuantity(booking) {
  return booking.serviceQuantity;
}

export function gtmCurrencyCode(booking) {
  return booking.total.currency;
}
