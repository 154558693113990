import { withTheme } from "styled-components";
import { Box } from "rebass/styled-components";
import LogoSvg from "./icons/logo.svg";

interface Props {
  [x: string]: any;
}

const Logo = (props: Props) => {
  const { theme, serviceTypeId, color, width, height, ...rest } = props;

  return (
    <Box width={width} height={height}>
      <LogoSvg
        width="100%"
        height="100%"
        color={theme.colors[color || "inuOrange"]}
        {...rest}
      />
    </Box>
  );
};
export default withTheme(Logo);
