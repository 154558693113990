import { ApolloLink, Observable } from "apollo-link";
import { setContext } from "apollo-link-context";
import getConfig from "next/config";
import cookie from "cookie";

const { publicRuntimeConfig } = getConfig();

if (typeof window !== "undefined") {
  var ActionCable = require("actioncable");
  var printer = require("graphql/language/printer");
}

let token = null;
let cable = "";

export default function ActionCableLink(getToken) {
  const ActionCableAuthLink = setContext(() => {
    const tokenCookie = cookie.parse(document.cookie)["token"];
    if (tokenCookie == token) {
      return { cable, token };
    } else if (cable) {
      cable.disconnect();
    }
    token = tokenCookie;
    cable = ActionCable.createConsumer(
      `${publicRuntimeConfig.gudogCable}?token=${token}`
    );
    return { cable, token };
  });

  const ActionCableApolloLink = new ApolloLink(function(operation) {
    return new Observable(function(observer) {
      let channelName = "GraphqlChannel";
      let actionName = "execute";
      let connectionParams = {};
      let channelId = Math.round(Date.now() + Math.random() * 100000).toString(
        16
      );

      let subscription = cable.subscriptions.create(
        Object.assign(
          {},
          {
            channel: channelName,
            channelId: channelId
          },
          connectionParams
        ),
        {
          connected: function() {
            this.perform(actionName, {
              query: operation.query ? printer.print(operation.query) : null,
              variables: operation.variables,
              operationId: operation.operationId,
              operationName: operation.operationName
            });
          },
          received: function(payload) {
            if (payload.result.data || payload.result.errors) {
              observer.next(payload.result);
            }

            if (!payload.more) {
              this.unsubscribe();
              observer.complete();
            }
          }
        }
      );

      return subscription;
    });
  });

  return ActionCableAuthLink.concat(ActionCableApolloLink);
}
