import { useRouter } from "next/router";

import publicRuntimeConfig from "../lib/public-runtime-config";
import routes from "../lib/routes.json";

type Route = keyof typeof routes;
type Locale = keyof typeof routes["/search"]["locales"];

function HrefLang() {
  const hrefLangAlternate = publicRuntimeConfig.hrefLangAlternate;

  const router = useRouter();
  const { pathname, query } = router;

  // Skip hreflang in paginated pages
  if (query.page) {
    return <></>;
  }

  return (
    <>
      {hrefLangAlternate.map(
        (
          {
            href,
            locale,
            target
          }: { href: string; locale: Locale; target: string },
          index: number
        ) => {
          const route = (
            routes[pathname as Route].locales[locale] ||
            routes[pathname as Route].locales["en"]
          ).replace("/", "");

          return (
            <link
              key={index}
              rel="alternate"
              href={`${href}${route}`}
              hrefLang={target}
            />
          );
        }
      )}
    </>
  );
}

export default HrefLang;
