export enum DatesFilterMode {
  MULTIPLE_DATES = "MultipleDates",
  DATE_RANGE = "DateRange"
}

export enum TimeSelectorMode {
  DROPOFF_PICKUP_TIME = "DropOffPickupTime",
  START_TIME = "StartTime"
}

export enum BookingStatus {
  REQUESTED = "requested",
  OWNER_PREAPPROVED = "owner_preapproved",
  OWNER_REJECTED = "owner_rejected",
  OWNER_EXPIRED = "owner_expired",
  CAREGIVER_APPROVED = "caregiver_approved",
  CAREGIVER_REJECTED = "caregiver_rejected",
  CAREGIVER_EXPIRED = "caregiver_expired",
  FAILED = "failed",
  CONFIRMED = "confirmed",
  PENDING_CANCELATION = "pending_cancelation",
  CANCELED = "canceled",
  CAREGIVER_PAID = "caregiver_paid"
}

export enum BookingEvent {
  OWNER_PREAPPROVE = "OWNER_PREAPPROVE",
  OWNER_CANCEL = "OWNER_CANCEL",
  CAREGIVER_APPROVE = "CAREGIVER_APPROVE",
  OWNER_REJECT = "OWNER_REJECT",
  CAREGIVER_REJECT = "CAREGIVER_REJECT",
  CAREGIVER_CONFIRM = "CAREGIVER_CONFIRM",
  OWNER_CONFIRM = "OWNER_CONFIRM",
  EDIT_REQUEST = "EDIT_REQUEST",
  CAREGIVER_PAYOUT_BOOKING = "CAREGIVER_PAYOUT_BOOKING",
  CAREGIVER_CANCEL = "CAREGIVER_CANCEL"
}

export enum CancelationReasonType {
  UNNECESSARY = "UNNECESSARY",
  ACCIDENT = "ACCIDENT",
  TRIP_CANCELED = "TRIP_CANCELED",
  DONT_LIKE_CAREGIVER = "DONT_LIKE_CAREGIVER",
  OTHER = "OTHER"
}

export enum Currency {
  "EUR" = "EUR",
  "GBP" = "GBP",
  "DKK" = "DKK",
  "NOK" = "NOK",
  "SEK" = "SEK"
}

export enum MessageType {
  "NORMAL" = "NORMAl",
  "IMAGE" = "IMAGE",
  "BOOKING" = "BOOKING"
}

export enum BillingBankWireStatus {
  "SUCCEEDED" = "SUCCEEDED",
  "FAILED" = "FAILED",
  "CREATED" = "CREATED"
}

export enum PropertyType {
  RANCH = "RANCH",
  HOUSE = "HOUSE",
  APARTMENT = "APARTMENT"
}

export enum CaregiverStatus {
  PENDING = "PENDING",
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
  INCOMPLETE = "INCOMPLETE",
  REJECTED = "REJECTED"
}

export enum UserType {
  CAREGIVER = "Caregiver",
  USER = "User",
  ADMIN = "Admin"
}

export enum ThirdPartyAuth {
  FB = "facebook",
  APPLE = "apple"
}
