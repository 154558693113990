import { withTheme } from "styled-components";
import { Image, Box } from "rebass/styled-components";

interface Props {
  src: string;
  showBadge: any;
  [x: string]: any;
  alt?: string;
}

const AvatarBadge = (props: Props) => {
  const { src, alt, showBadge, ...rest } = props;
  return (
    <Box
      sx={{
        position: "relative"
      }}
    >
      {showBadge && (
        <Box
          as="span"
          sx={{
            position: "absolute",
            top: "0",
            right: "4px",
            bg: "inuOrange",
            textAlign: "center",
            color: "samoyedWhite",
            borderRadius: "circle",
            padding: "6px 6px"
          }}
        ></Box>
      )}
      <Image
        alt={!!alt ? alt : "avatar"}
        src={src}
        bg="white"
        variant="avatar"
        width={32}
        height={32}
        {...rest}
      />
    </Box>
  );
};
export default withTheme(AvatarBadge);
