import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { Box } from "rebass/styled-components";

interface Props {
  delay: number;
  fixed: boolean;
}

const ProgressBar = ({ delay, fixed }: Props) => {
  const router = useRouter();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    const routeChangeStarts = () => {
      const intervalStep = 10;
      const intervalDuration = delay / (100 * intervalStep);

      interval = setInterval(() => {
        setProgress(currentProgress => {
          if (currentProgress < 100) {
            return currentProgress + 1 / intervalDuration;
          } else {
            clearInterval(interval); // Stop the interval once we reach 100
            return currentProgress;
          }
        });
      }, intervalDuration);
    };

    const routeChangeEnds = () => {
      //complete loading
      clearInterval(interval);
      setProgress(100);

      //reset loading
      setTimeout(() => {
        setProgress(0);
      }, 500);
    };

    //setting proper behaviour on route change events
    router.events.on("routeChangeStart", () => routeChangeStarts());
    router.events.on("routeChangeError", () => routeChangeEnds());
    router.events.on("routeChangeComplete", () => routeChangeEnds());
  }, [router, delay]);

  return (
    <Box
      sx={{
        svg: {
          zIndex: 6,
          position: fixed ? "fixed" : "absolute",
          top: 0,
          left: 0,
          right: 0,
          pointerEvents: "none",
          height: "3px",
          path: {
            stroke: "inuOrange"
          }
        }
      }}
    >
      <svg
        viewBox="0 0 100 1"
        preserveAspectRatio="none"
        style={{ display: "block", width: `${progress}%` }}
      >
        <path
          d="M 0,0.5 L 100,0.5"
          strokeWidth="1"
          fillOpacity="0"
          style={{ strokeDasharray: "100, 100", strokeDashoffset: 0 }}
        ></path>
      </svg>
    </Box>
  );
};

ProgressBar.defaultProps = {
  delay: 5000
};

export default ProgressBar;
